import Image from 'next/image';
import type { SerializedStyles } from '@emotion/react';
import { MotionValue, motion, useTransform } from 'framer-motion';
import { MouseEventHandler, useRef } from 'react';
import ArrowIcon from '@zola/zola-ui/src/components/SvgIcons/Arrow';
import HighlightTitle from '@zola/zola-ui/src/components/PreAuthV3/HighlightTitle';
import { isDesktop } from '@zola-helpers/client/dist/es/util/responsive';
import { desktopOnly, mobileOnly } from '@zola/zola-ui/src/styles/emotion/mixins/responsive';

// Utils
import useRefScrollProgress from '~/hooks/useRefScrollProgress';

// Assets

import GuestListAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/Grid/assets/guest_list.png';
import WebsiteAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/Grid/july2024/website_grid_ui.webp';
import VendorAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/Grid/assets/vendor.png';
import PaperAssetDesktop from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/Grid/assets/paper_desktop.png';
import RegistryAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/Grid/assets/registry.png';
import SupportAsset from '../../../../../../public/static-preauth/images/Homepage/NewHomepage/Grid/july2024/advice_grid_ui.webp';

import {
  Grid,
  GridBox,
  GridDescription,
  GridHeader,
  GridImageWrapper,
  guestListFgOverrides,
  vendorFgOverrides,
  paperFgOverrides,
  registryFgOverrides,
  adviceFgOverrides,
  SectionWrapper,
  Sidebar,
  Subtitle,
  TitleWrapper,
  websiteFgOverrides,
  GridBackgroundWrapper,
  GridForegroundImage,
  tabletOnly,
} from './SolutionGrid2.styles';

const GuestListGridDesktopWebp =
  '/static-preauth/images/Homepage/NewHomepage/Grid/july2024/guestlist_grid_bg.webp';
const WebsiteGridDesktopWebp =
  '/static-preauth/images/Homepage/NewHomepage/Grid/july2024/website_grid_bg.webp';
const VenuesGridDesktopWebp =
  '/static-preauth/images/Homepage/NewHomepage/Grid/desktop/venues.webp';
const PaperGridDesktopWebp = '/static-preauth/images/Homepage/NewHomepage/Grid/desktop/paper.webp';
const RegistryGridDesktopWebp =
  '/static-preauth/images/Homepage/NewHomepage/Grid/desktop/registry.webp';
const AdviceGridDesktopWebp =
  '/static-preauth/images/Homepage/NewHomepage/Grid/july2024/advice_grid_bg.webp';

type SolutionGridProps = {
  scrollYProgress: MotionValue<number>;
};

const SolutionGrid: React.FC<SolutionGridProps> = ({ scrollYProgress }): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  const tabletSettings = {
    keyframes: [0.05, 0.3],
    outputRange: [-50, -125],
    smallerOutputRange: [0, -50],
  };
  const desktopSettings = {
    keyframes: [0, 1],
    outputRange: [5, -75],
    smallerOutputRange: [10, -20],
  };

  const desktopInputRange = useRefScrollProgress(ref, desktopSettings.keyframes, false);
  const tabletInputRange = useRefScrollProgress(ref, tabletSettings.keyframes, true);
  const desktopTransformedY = useTransform(
    scrollYProgress,
    desktopInputRange,
    desktopSettings.outputRange
  );
  const tabletTransformedY = useTransform(
    scrollYProgress,
    tabletInputRange,
    tabletSettings.outputRange
  );

  // for images with less flex on the top and bottom, this reduces the range for the parallax
  const desktopTransformYSmaller = useTransform(
    scrollYProgress,
    desktopInputRange,
    desktopSettings.smallerOutputRange
  );
  const tabletTransformYSmaller = useTransform(
    scrollYProgress,
    tabletInputRange,
    tabletSettings.smallerOutputRange
  );

  type GridContent = {
    href: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    title: string;
    description: string;
    imageElement: JSX.Element;
  };

  type GridImageProps = {
    background: { src: string; alt: string; objectPosition?: string };
    foreground?: { src: string; alt: string; css?: SerializedStyles };
    smaller?: boolean;
  };
  const renderGridImage = ({
    background,
    foreground,
    smaller = false,
  }: GridImageProps): JSX.Element => {
    const GridImg = (
      <Image
        key="hp-grid-bg"
        src={background.src}
        alt={background.alt}
        layout="fill"
        objectFit="cover"
        sizes="(max-width: 1023px) 33vw, 50vw"
        objectPosition={background.objectPosition || 'center'}
      />
    );

    return (
      <GridImageWrapper>
        <GridBackgroundWrapper>
          <motion.div
            style={{ y: smaller ? desktopTransformYSmaller : desktopTransformedY }}
            css={desktopOnly}
          >
            {GridImg}
          </motion.div>
          <motion.div
            style={{ y: smaller ? tabletTransformYSmaller : tabletTransformedY }}
            css={tabletOnly}
          >
            {GridImg}
          </motion.div>
          <div css={mobileOnly}>{GridImg}</div>
        </GridBackgroundWrapper>
        {foreground && (
          <GridForegroundImage src={foreground.src} alt="" css={foreground.css} loading="lazy" />
        )}
      </GridImageWrapper>
    );
  };

  const gridContent: GridContent[] = [
    {
      href: '/wedding/onboard/registry',
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        /** Based on A/B test results for 'core-homepage-grid-registry-route',
         * mobile and desktop redirects differ
         */
        if (isDesktop()) {
          window.location.assign('/wedding-registry');
        } else {
          window.location.assign('/wedding/onboard/registry');
        }
      },
      title: 'Registry',
      description: 'The gifts you want, the way you want them.',
      imageElement: renderGridImage({
        background: { src: RegistryGridDesktopWebp, alt: '' },
        foreground: { src: RegistryAsset, alt: '', css: registryFgOverrides },
      }),
    },
    {
      href: '/wedding/onboard/wedding-website',
      onClick: e => {
        e.preventDefault();
        e.stopPropagation();
        /** Based on A/B test results for 'core-homepage-grid-website-route',
         * mobile and desktop redirects differ
         */
        if (isDesktop()) {
          window.location.assign('/wedding-planning/website');
        } else {
          window.location.assign('/wedding/onboard/wedding-website');
        }
      },
      title: 'Free wedding websites',
      description: 'A free, easy way to keep guests in-the-know.',
      imageElement: renderGridImage({
        background: { src: WebsiteGridDesktopWebp, alt: '' },
        foreground: { src: WebsiteAsset, alt: '', css: websiteFgOverrides },
        smaller: true,
      }),
    },
    {
      href: '/wedding-vendors',
      title: 'Venues and vendors',
      description: 'Find the people to bring it all to life.',
      imageElement: renderGridImage({
        background: { src: VenuesGridDesktopWebp, alt: '' },
        foreground: { src: VendorAsset, alt: '', css: vendorFgOverrides },
        smaller: true,
      }),
    },
    {
      href: '/wedding-planning/save-the-date/shop',
      title: 'Invites and paper',
      description: 'Set the vibe with ultra-customizable designs.',
      imageElement: renderGridImage({
        background: { src: PaperGridDesktopWebp, alt: '' },
        foreground: { src: PaperAssetDesktop, alt: '', css: paperFgOverrides },
        smaller: true,
      }),
    },
    {
      href: '/wedding-planning/guests',
      title: 'Guest list',
      description: 'We’ll help you get addresses and track RSVPs.',
      imageElement: renderGridImage({
        background: { src: GuestListGridDesktopWebp, alt: '', objectPosition: 'top' },
        foreground: { src: GuestListAsset, alt: '', css: guestListFgOverrides },
        smaller: true,
      }),
    },
    {
      href: '/expert-advice',
      title: 'Advice and support',
      description: 'There’s always someone in your corner.',
      imageElement: renderGridImage({
        background: { src: AdviceGridDesktopWebp, alt: '' },
        foreground: { src: SupportAsset, alt: '', css: adviceFgOverrides },
        smaller: true,
      }),
    },
  ];

  const GridBoxes = gridContent.map((gridItem, index) => {
    const hed = (
      <>
        {gridItem.title}
        <ArrowIcon flip="horizontal" showTitle={false} />
      </>
    );
    return (
      <GridBox key={`grid-box-${index}`}>
        <div>
          <GridHeader href={gridItem.href} onClick={gridItem.onClick}>
            {hed}
          </GridHeader>
          <GridDescription>{gridItem.description}</GridDescription>
        </div>
        {gridItem.imageElement}
      </GridBox>
    );
  });

  return (
    <SectionWrapper ref={ref}>
      <Sidebar>
        <TitleWrapper>
          <HighlightTitle isH1={false}>
            <>
              <span>Everything you need</span> to plan the wedding you want
            </>
          </HighlightTitle>
          <Subtitle>For all the days along the way</Subtitle>
        </TitleWrapper>
      </Sidebar>
      <Grid>{GridBoxes}</Grid>
    </SectionWrapper>
  );
};

export default SolutionGrid;
