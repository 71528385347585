import { SearchAction, WebSite, WithContext } from 'schema-dts';
import { ORGANIZATION_SCHEMA } from './organizationSchema';

export const HOMEPAGE_SCHEMA: WithContext<WebSite> = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://www.zola.com/',
  name: 'Zola',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://www.zola.com/search/gifts?q={search_term_string}',
    'query-input': 'required name=search_term_string',
  } as SearchAction,
  description:
    'Zola is a comprehensive wedding planning platform that offers tools for venues, invites, registries, and more.',
  publisher: ORGANIZATION_SCHEMA,
};
