import { Organization } from 'schema-dts';

export const ORGANIZATION_SCHEMA: Organization = {
  '@type': 'Organization',
  name: 'Zola, Inc.',
  url: 'https://www.zola.com/',
  logo: {
    '@type': 'ImageObject',
    url: 'https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/logos/zola-logomark-marine.svg',
    width: '600',
    height: '92',
  },
  address: {
    '@type': 'PostalAddress',
    streetAddress: '7WTC, 250 Greenwich St., 39th Floor',
    addressLocality: 'New York',
    addressRegion: 'NY',
    postalCode: '10007',
    addressCountry: 'US',
  },
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+1-408-657-9652',
    contactType: 'Customer Service',
    email: 'support@zola.com',
  },
  sameAs: [
    'https://www.facebook.com/zola',
    'https://www.pinterest.com/zola',
    'https://www.twitter.com/zola',
    'https://www.instagram.com/zola',
    'https://www.tiktok.com/@zola',
    'https://www.linkedin.com/company/zola-com/',
  ],
};
