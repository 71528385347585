/* eslint-disable sonarjs/no-duplicate-string */
import {
  InlineAccordion,
  InlineAccordionItemType,
} from '@zola/zola-ui/src/components/InlineAccordion/InlineAccordion';
import { Title2 } from '@zola/zola-ui/src/typography/Headings';
import { US_STATES } from '~/libs/universal/utils/states';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { WSearchLocationView } from '@zola/svc-web-api-ts-client';
import { getVendorWordCategory } from '@zola-helpers/client/dist/es/marketplace/vendorWords';
import { Link, List, Title, VendorCrossLinksContainer } from './VendorCrossLinks.styles';
import {
  topLocationsAndCategories,
  vendorsNearMeLinks,
  venuesNearMeLinks,
} from './vendorCrossLinkData';

const getStateItem = (type: 'venues' | 'vendors'): InlineAccordionItemType => {
  let category = 'Wedding Vendors';
  let slug = 'vendors';

  if (type === 'venues') {
    category = 'Wedding Venues';
    slug = 'venues';
  }

  return {
    title: (
      <Title2 css={Title} presentation="h6">
        {category} by State
      </Title2>
    ),
    content: (
      <ul css={List}>
        {US_STATES.map(state => {
          const url = `/wedding-vendors/states/${state.slug}/${slug}`;
          return (
            <li key={state.slug}>
              <LinkV2 css={Link} href={url} noTextTransform subtle>
                {state.name} {category}
              </LinkV2>
            </li>
          );
        })}
      </ul>
    ),
  };
};

const getAlphabeticalItem = (): InlineAccordionItemType => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  return {
    title: (
      <Title2 css={Title} presentation="h6">
        Wedding Vendors Alphabetically
      </Title2>
    ),
    content: (
      <ul css={List}>
        {letters.map(letter => {
          const url = `/wedding-vendors/list/${letter}`;
          return (
            <li key={letter}>
              <LinkV2 css={Link} href={url} noTextTransform subtle>
                Wedding Vendors {letter}
              </LinkV2>
            </li>
          );
        })}
        <li>
          <LinkV2 css={Link} href="/wedding-vendors/list/0-9" noTextTransform subtle>
            Wedding Vendors #
          </LinkV2>
        </li>
      </ul>
    ),
  };
};

const getTopCitiesItem = (
  type: 'venues' | 'vendors',
  topLocations: WSearchLocationView[]
): InlineAccordionItemType => {
  let category = 'Wedding Vendors';
  let slug = '';

  if (type === 'venues') {
    category = 'Wedding Venues';
    slug = 'wedding-venues';
  }

  return {
    title: (
      <Title2 css={Title} presentation="h6">
        {category} in Top Cities
      </Title2>
    ),
    content: (
      <ul css={List}>
        {topLocations.map(location => {
          const searchSlug = [location.slug, slug].filter(Boolean).join('--');
          const url = `/wedding-vendors/search/${searchSlug}`;
          return (
            <li key={location.slug}>
              <LinkV2 css={Link} href={url} noTextTransform subtle>
                {category} in {location.label}
              </LinkV2>
            </li>
          );
        })}
      </ul>
    ),
  };
};

const getTopCitiesAndCategoriesItem = (): InlineAccordionItemType => {
  return {
    title: (
      <Title2 css={Title} presentation="h6">
        Wedding Vendors in Top Cities
      </Title2>
    ),
    content: (
      <ul css={List}>
        {topLocationsAndCategories.map((locationAndCategory, index) => {
          const { taxonomyKey, locationSlug, locationLabel } = locationAndCategory;
          const category = getVendorWordCategory(taxonomyKey);
          const url = `/wedding-vendors/search/${locationSlug}--${taxonomyKey}`;
          return (
            <li key={index}>
              <LinkV2 css={Link} href={url} noTextTransform subtle>
                Wedding {category} in {locationLabel}
              </LinkV2>
            </li>
          );
        })}
      </ul>
    ),
  };
};

const getNearMeItem = (type: 'venues' | 'vendors'): InlineAccordionItemType => {
  let category = 'Wedding Vendors';
  let nearMeLinks = vendorsNearMeLinks;

  if (type === 'venues') {
    category = 'Wedding Venues';
    nearMeLinks = venuesNearMeLinks;
  }

  return {
    title: (
      <Title2 css={Title} presentation="h6">
        {category} Near Me
      </Title2>
    ),
    content: (
      <ul css={List}>
        {nearMeLinks.map((link, index) => {
          const { title, searchSlug } = link;
          const url = `/wedding-vendors/near-me/${searchSlug}`;
          return (
            <li key={index}>
              <LinkV2 css={Link} href={url} noTextTransform subtle>
                {title}
              </LinkV2>
            </li>
          );
        })}
      </ul>
    ),
  };
};

interface VendorCrossLinksProps {
  topLocations: WSearchLocationView[];
}

export const VendorCrossLinks = (props: VendorCrossLinksProps) => {
  const { topLocations } = props;
  const items = [getStateItem('venues'), getStateItem('vendors'), getAlphabeticalItem()];

  if (topLocations.length > 0) {
    items.push(getTopCitiesItem('venues', topLocations));
    items.push(getTopCitiesAndCategoriesItem());
  }

  items.push(getNearMeItem('venues'), getNearMeItem('vendors'));

  return (
    <div css={VendorCrossLinksContainer}>
      <InlineAccordion items={items} />
    </div>
  );
};
